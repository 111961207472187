<template>
  <v-dialog
    :value="true"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    transition="slide-y-reverse-transition"
    max-width="1000"
    scrollable
    persistent
  >
    <SupplierAgreementForm
      :disabled="$store.getters.loading[`put:api/documents/${editedSupplierAgreement.id}`]"
      :errors="supplierAgreementValidationErrors"
      :supplier-agreement="editedSupplierAgreement"
      :statuses="supplierAgreementStatuses"
      @clear:errors="CLEAR_SUPPLIER_AGREEMENT_VALIDATION_ERRORS"
      @cancel="goToSupplierAgreementsPage"
      @save="onSave"
    />
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import store from '@/store';
import SupplierAgreementForm from '@/components/forms/SupplierAgreementForm';

export default {
  name: 'EditSupplierAgreement',

  components: { SupplierAgreementForm },

  computed: {
    ...mapState('supplierAgreements', [
      'editedSupplierAgreement',
      'supplierAgreementValidationErrors',
      'supplierAgreementFilterParams',
    ]),
    ...mapGetters('supplierAgreements', ['supplierAgreementStatuses']),
  },

  beforeRouteEnter(to, from, next) {
    store
      .dispatch('supplierAgreements/editSupplierAgreement', +to.params.id)
      .then(() => {
        next();
      })
      .catch(() => {
        next({
          name: 'supplierAgreements',
          query: store.state.supplierAgreements.supplierAgreementFilterParams,
        });
      });
  },

  methods: {
    ...mapActions('supplierAgreements', ['updateSupplierAgreement']),
    ...mapMutations('supplierAgreements', ['CLEAR_SUPPLIER_AGREEMENT_VALIDATION_ERRORS']),

    onSave(supplierAgreement) {
      this.updateSupplierAgreement(supplierAgreement).then(() => {
        this.goToSupplierAgreementsPage();
      });
    },

    goToSupplierAgreementsPage() {
      this.$router.push({ name: 'supplierAgreements', query: this.supplierAgreementFilterParams });
    },
  },
};
</script>
